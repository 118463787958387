/* You can add global styles to this file, and also import other style files */
:root {
  --spacer: 1rem;
}

.map-container {
  z-index: 0;
  padding-bottom: 380px;
  height: 380px !important;
  width: 100%;
}

@media (min-width: 1100px) {
  .map-container {
    height: 900px !important;
  }
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.mt-5 {
  margin-top: calc(var(--spacer) * 3);
}

button:disabled, button[disabled] {
  border: none;
  background-color: #eaeaea;
  color: #b2a8a8;
  pointer-events: none;
  user-select: none;
}
